import { Exportable } from "@models/exporter.model";
import { Device } from "./device.model";

export class DeviceImportResult implements Exportable {
  devicesImported: Device[] = [];
  pendingImports: PendingImport[] = [];
}

interface PendingImport {
  serialBoard: string;
  imei: string;
  iccid: string;
  lot: string;
  manufacturer: string;
  manufacturedDate: string;
  warnings: string[];
  errors: string[];
}
