// Models
import { Firmware } from "@configuration/firmwares/models";
import { Hardware } from "@configuration/hardwares/models";
import { Customer } from "@customers/customers-mgmt/models";
import { Broker } from "@mqtt/brokers/models";
import { QueryFilter } from "@models/api-request.model";
import { Exportable, Exporter } from "@models/index";
import { DEVICE_STATUS_TYPE } from "@enums/device-status-type.enum";
import { Equipment } from "@equipments/equipments-mgmt/models";
import { DEVICE_MODE_TYPE } from "@enums/device-mode-type.enum";

export class Device implements Exportable {


  id:               number = 0;
  version:          number | undefined;
  serial:           string | undefined;
  imei:             string | undefined;
  iccid:             string | undefined;
  connectorsBoardHardware: string | undefined;
  lot:              string | undefined;
  manufacturer:     string | undefined;
  serialBattery:    string | undefined;
  serialBoard:      string | undefined;
  serialLock:       string | undefined;
  serialReader:     string | undefined;
  manufacturedDate: Date = new Date();
  registrationDate: Date = new Date();
  registrationOperator: Date = new Date();
  setupDate:        Date = new Date();
  setupOperator:    string | undefined;
  observations:     string | undefined;
  lastTransaction:  string | undefined;
  deviceLastModeType: DEVICE_MODE_TYPE | undefined;
  deviceLastModeAt: Date = new Date();
  deviceLastModeOperator: string | undefined;
  modeStoreAt: Date = new Date();
  modeSetupAt: Date = new Date();
  modeStreetAt: Date = new Date();
  modeSurvivalAt: Date = new Date();
  deviceLastStatusType: DEVICE_STATUS_TYPE | undefined;
  deviceLastStatusAt: Date = new Date();
  deviceLastStatusOperator: string | undefined;
  statusAddedAt: Date = new Date();
  statusRegisteredAt: Date = new Date();
  statusValidatedAt: Date = new Date();
  statusErrorAt: Date = new Date();
  statusRequireRevisionAt: Date = new Date();
  statusDiscontinuedAt: Date = new Date();
  matchedAt: Date = new Date();
  deviceInstallationStateType: number | undefined;
  deviceLastInstallationStateAt: Date | undefined;

  customerId:       number | undefined;
  firmwareId:       number | undefined;
  hardwareId:       number | undefined;
  brokerId:         number | undefined;

  customer:         Customer = new Customer();
  firmware:         Firmware = new Firmware();
  hardware:         Hardware = new Hardware();
  broker:           Broker = new Broker();


  //transient attributes
  equipment:        Equipment | undefined;


  constructor(){}

}

export class DeviceFilter implements QueryFilter {

  serial:      string | undefined;
  serialBoard: string | undefined;
  imei:        string | undefined;
  operator:    string | undefined;
}


export class DeviceExporter implements Exporter  {

  serialBoard;
  imei;
  operator;
  lot;
  manufacturer;
  manufacturedDate;
  registrationDate;
  setupDate;
  observations;
  firmware;
  hardware;






  constructor() {

    this.serialBoard= {
      nameToShow: 'Nº Serie MC'
    };
    this.imei = {
      nameToShow: 'IMEI'
    };

    this.operator = {
      nameToShow: 'Operador'
    };

    this.lot = {
      nameToShow: 'Lote'
    };

    this.manufacturer = {
      nameToShow: 'Fabricante'
    };

    this.manufacturedDate = {
      nameToShow: 'Fecha de Fabricación'
    };
    this.registrationDate = {
      nameToShow: 'Fecha de Registro'
    };

    this.setupDate = {
      nameToShow: 'Fecha de Instalación'
    };

    this.observations = {
      nameToShow: 'Observaciones'
    };

    this.firmware = {
      name: {
        nameToShow: 'Firmware'
      }
    };

    this.hardware = {
      name: {
        nameToShow: 'Hardware'
      }
    };

  }
}




